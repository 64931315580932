import { Link } from "react-router-dom";
import { TimeDate } from "../Components/Help.component";
import Modal from "../Components/Modal.component";
import { useState } from "react";

const Left = ({ detail }) => {
  const { title, time, browse, contact, content, type } = detail;
  const [showModal, setShowModal] = useState(false);
  const [header, setHeader] = useState("交易流程");
  const [info, setInfo] = useState(
    "1.联系对方,确认信息 2.币安担保,拉群或发广告联系 @cpdd 3.交易前注意验群,其他担保勿扰"
  );

  return (
    <div className="left w-[860px] min-h-[652px] bg-[#f5f5f5]	py-[50px] px-[50px] flex flex-col relative rounded-[20px]">
      {showModal && (
        <Modal
          header={header}
          info={info}
          onclickClose={() => setShowModal(false)}
        />
      )}
      {/* <Modal
        header="交易流程"
        info="1.联系对方,确认信息 2.汇旺担保,拉群或发广告联系 @hwdb 3.交易前注意验群,其他担保勿扰"
        onclickClose={() => setShowModal(false)}
      /> */}
      <div className="left-top">
        <div className="left-top-title  mb-[10px]">
          <div className=" h-[34px] mt-[20px] content-center flex flex-row  leading-[34px]  ">
            <div
              className={` text-center w-[50px] h-full rounded-l-full ${
                type === 1 ? "bg-[#c00808] text-white" : "bg-[#d3d3d3]"
              } `}
            >
              供
            </div>
            <div
              className={` text-center w-[50px] h-full rounded-r-full  ${
                type === 2 ? "bg-[#ce7205] text-white" : "bg-[#d3d3d3]"
              }`}
            >
              {type === 2 ? `求` : "需"}
            </div>
            <div className="ml-[20px] font-semibold">{title}</div>
          </div>
        </div>
        {/* <div className="left-top-content">
          {TimeDate(time)} 阅读 {browse} 次
        </div> */}
      </div>
      <div className="left-body">{content}</div>
      <div className="left-contact">
        <div className="left-contact-title">联系方式:{contact}</div>
      </div>
      <div className="left-bottom">
        <p>
          ⭐️只走币安担保,拉群或发广告联系 @cpdd ,交易前注意验群,其他担保勿扰⭐️
        </p>
      </div>
      <div className="left-button absolute left-0 bottom-0 flex flex-row justify-center w-full px-4 py-4 gap-[30px]">
    
        <Link to="/gongqun" onClick={() => {window.scrollTo(0, 0)}}>

          <button className="w-[210px] h-[50px] bg-transparent text-[#c3000b] border border-[#c3000b] px-[20px] py-[5px] rounded-[5px]">
            联系公群
          </button>
        </Link>
        <button
          className="w-[210px] h-[50px] bg-transparen text-[#0d6fc4] border border-[#0d6fc4] px-[20px] py-[5px] rounded-[5px]"
          onClick={() => {
            setShowModal(true);
            setHeader("交易流程");
            setInfo(
              "1.联系对方,确认信息 2.币安担保,拉群或发广告联系 @cpdd 3.交易前注意验群,其他担保勿扰"
            );
          }}
        >
          交易流程
        </button>
        <button
          className="w-[210px] h-[50px] bg-transparen text-[#7e129b] border border-[#7e129b] px-[20px] py-[5px] rounded-[5px]"
          onClick={() => {
            setShowModal(true);
            setHeader("举报");
            setInfo("举报请联系 @JianDu 或者 @JianCha");
          }}
        >
          举报
        </button>
      </div>
    </div>
  );
};

export default Left;
